import React from 'react';
import styled from '@emotion/styled';
import Body2 from '../Typography/Body/Body2';
import Headline2 from '../Typography/Heading/Headline2';
import SpacingContainer from '../SpacingContainer';

const ProcessingContainer = styled.div`
  padding: 1.5rem 1rem;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  margin: 1rem 0;
`;

const StyledHeadline2 = styled(Headline2)`
  color: #fff;
`;

const StyledBody2 = styled(Body2)`
  color: #fff;
`;

const ProcessingPayment = () => (
  <ProcessingContainer>
    <StyledHeadline2>Processing your payment</StyledHeadline2>
    <SpacingContainer marginBottom="8px" />
    <StyledBody2>
      Please wait while we process your payment. This might take a moment.
    </StyledBody2>
    <SpacingContainer marginBottom="8px" />
    <StyledBody2>
      Need Help or Have Questions? Our customer service is available on
      weekdays from 08:30 AM to 5:00 PM CET.
    </StyledBody2>
    <SpacingContainer />
    <StyledBody2 tag="div">
      <div>Telephone: +31 206154241</div>
      <div>Email: subscriptions@fashionunited.com</div>
    </StyledBody2>
  </ProcessingContainer>
);

export default ProcessingPayment;
