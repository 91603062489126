import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import ArticleBody from './InnerHtmlStyling';

const articleSamplePropertyTypes = {
  Component: PropTypes.elementType,
};

type ArticleSampleProperties = InferProps<
typeof articleSamplePropertyTypes
>;

const TruncatedArticleBody = styled(ArticleBody)`
  max-height: 14rem;
  overflow: hidden;
`;

const ArticleSample = ({
  Component,
}: ArticleSampleProperties) => (
  <>
    <TruncatedArticleBody
      className="member-content"
    />
    {Component && (<Component />)}
  </>
);

export default ArticleSample;
